<template>
  <div v-if="items" id="printinvoice" class="app-invoice-preview">
    <v-card>
      <!-- Header -->
      <!--
      <v-card-text class="py-9 px-8">
        <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
          &lt;!&ndash; Left Content &ndash;&gt;
          <div class="mb-8 mb-sm-0">
            <div class="d-flex align-center mb-6">
              <span class="text&#45;&#45;primary font-weight-bold text-xl"> </span>
            </div>
            <span class="d-block text&#45;&#45;black">Office 149, 450 South Brand Brooklyn</span>
            <span class="d-block">San Diego County, CA 91905, USA</span>
            <span class="d-block">+1 (123) 456 7891, +44 (876) 543 2198</span>
          </div>
          &lt;!&ndash; Right Content &ndash;&gt;
          &lt;!&ndash; <div>
              <p class="font-weight-medium text-xl text&#45;&#45;primary mb-4">Invoice #{{ invoiceData.id }}</p>
              <p class="mb-2">
                <span>Date Issued: </span>
                <span class="font-weight-semibold">{{ invoiceData.issuedDate }}</span>
              </p>
              <p class="mb-2">
                <span>Due Date: </span>
                <span class="font-weight-semibold">{{ invoiceData.dueDate }}</span>
              </p>
            </div> &ndash;&gt;
        </div>
      </v-card-text>
-->
      <v-divider></v-divider>

      <!-- Payment Details -->
      <v-card-text class="py-9 px-8">
        <v-row align="center" no-gutters>
          <v-col cols="auto">
            <img src="@/assets/images/svg/SuperWA-Logo-1.svg" style="object-fit: contain; width: 20px" />
          </v-col>
          <v-col class="mx-2">
            <h3>MARKETA CHAT</h3>
          </v-col>
        </v-row>
        <br />
        <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
          <div class="mb-8 mb-sm-0">
            <p class="mb-1">Cartenz</p>
            <p class="mb-1">
              Gedung AIA Central, Lantai 25 Jln. Jend. Sudirman Kav 48A, Jakarta Selatan, 12930, indonesia
            </p>
            <p class="mb-1">021-29772929</p>
          </div>
          <div>
            <p class="font-weight-semibold payment-details-header">Invoice :</p>
            <table>
              <tr>
                <td class="pe-6">Invoice number:</td>
                <td>{{ items.invoice_number }}</td>
              </tr>
              <tr>
                <td class="pe-6">Date paid:</td>
                <td>{{ $moment(items.paid_on).format('YYYY MMM DD') }}</td>
              </tr>
              <tr>
                <td class="pe-6">Payment method:</td>
                <td>{{ capitalizeFirstLetter(items.source) }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
          <div class="mb-8 mb-sm-0">
            <p class="font-weight-semibold payment-details-header">Paid by :</p>
            <p class="mb-1 font-weight-bold">
              {{ items.paid_by }}
            </p>
          </div>
        </div>
      </v-card-text>

      <!-- Table -->
      <table class="table-design">
        <thead>
          <tr class="text-uppercase">
            <th>Description/name</th>
            <th>QTY</th>
            <th>MONTH</th>
            <th>UNIT PRICE</th>
            <!--            <th>EXTRA</th>-->
            <!--            <th>discount</th>-->
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="item in purchasedProducts" :key="item.name"> -->
          <tr v-for="(d, i) in 1" :key="i + '-id'">
            <td class="text-no-wrap">
              {{ items.item_name }}
            </td>
            <td class="text-no-wrap">{{ items.package_month }}</td>
            <td class="text-no-wrap">Month</td>
            <td v-if="items.source === 'xendit'">
              <v-row no-gutters>
                <v-col>Rp</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'IDR', 'id') }} </v-col>
              </v-row>
            </td>
            <td v-else class="text-right">
              <v-row no-gutters>
                <v-col>$</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'USD', 'en') }} </v-col>
              </v-row>
            </td>
            <!--            <td>{{ items.extra }}</td>-->

            <!--            <td class="text-no-wrap">-->
            <!--              {{ items.package_month }}-->
            <!--            </td>-->

            <td v-if="items.source === 'xendit'">
              <v-row no-gutters>
                <v-col>Rp</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'IDR', 'id') }} </v-col>
              </v-row>
            </td>
            <td v-else class="text-right">
              <v-row no-gutters>
                <v-col>$</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'USD', 'en') }} </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td class="text-no-wrap" style="border: none" colspan="3" />
            <td class="text-no-wrap">Subtotal</td>
            <td v-if="items.source === 'xendit'">
              <v-row no-gutters>
                <v-col>Rp</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'IDR', 'id') }} </v-col>
              </v-row>
            </td>
            <td v-else class="text-right">
              <v-row no-gutters>
                <v-col>$</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_original_value), 'USD', 'en') }} </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td class="text-no-wrap" style="border: none" colspan="3" />

            <td class="text-no-wrap">Discount ({{ items.item_discount_name }})</td>
            <td v-if="items.source === 'xendit'">
              <v-row no-gutters>
                <v-col>Rp</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_discount_amount), 'IDR', 'id') }} </v-col>
              </v-row>
            </td>
            <td v-else class="text-right">
              <v-row no-gutters>
                <v-col>$</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_discount_amount), 'USD', 'en') }} </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td class="text-no-wrap" style="border: none" colspan="3" />
            <td class="text-no-wrap">Amount paid</td>
            <td v-if="items.source === 'xendit'">
              <v-row no-gutters>
                <v-col>Rp</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_value), 'IDR', 'id') }} </v-col>
              </v-row>
            </td>
            <td v-else class="text-right">
              <v-row no-gutters>
                <v-col>$</v-col>
                <v-col></v-col>
                <v-col cols="auto"> {{ currency(Number(items.item_value), 'USD', 'en') }} </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Total -->
      <!--
      <v-card-text class="py-9 px-8 break-footer">
        <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
          <div class="mb-2 mb-sm-0"></div>
          <div>
            <table>
              <tr>
                <td class="pe-16">Subtotal:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">$1800</th>
              </tr>
              <tr>
                <td class="pe-16">Discount:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">$28</th>
              </tr>
              <tr>
                <td class="pe-16">Tax:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">21%</th>
              </tr>
            </table>
            <v-divider class="mt-4 mb-3"></v-divider>
            <table class="w-full">
              <tr>
                <td class="pe-16">Total:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">$1690</th>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
-->

      <v-divider></v-divider>

      <!--      <v-card-text class="px-8 py-6">
        <p class="mb-0">
          <span class="font-weight-semibold">Note : </span>
          <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
            projects. Thank You!</span>
        </p>
      </v-card-text>-->
    </v-card>
    <!-- Right Column: Invoice Action -->
  </div>
</template>

<script>
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
// import puppeteer from 'puppeteer-web'
import { jsPDF } from 'jspdf'
import axios from 'axios'
// import themeConfig from '@themeConfig'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  data() {
    return {
      items: null,
    }
  },

  // components: {
  //   InvoiceSidebarSendInvoice,
  //   InvoiceSidebarAddPayment,
  // },
  created() {
    // this.getApi()
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/invoices/detail/${this.$route.params.id}`
    axios.get(endpoint).then(r => {
      if (r.data.status === true) {
        //console.log('checkData', r)
        this.items = r.data.data
        //console.log('items puppeteer', this.items, this.$route.params.id, location.href)
      }
      //console.log('invoice', r)
    })
  },
  async mounted() {
    this.$vuetify.theme.isDark = false
  },

  methods: {
    getApi() {
      this.$store.dispatch('subscriptions/getApiInvoice', this.$route.params.id).then(async r => {
        //console.log('checkData', r)
        this.items = r.data.data
        //console.log('items puppeteer', this.items, this.$route.params.id, location.href)

        if (this.items !== null) {
          // window.print()
        }
      })
    },
    currency(data, type = 'IDR', lang = 'id') {
      const currencyFractionDigits = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: type,
      }).resolvedOptions().maximumFractionDigits
      return data.toLocaleString(lang, { maximumFractionDigits: currencyFractionDigits })
    },
    capitalizeFirstLetter(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
    },
  },
  setup() {
    return {
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  color: black;
}

@import '~@core/preset/preset/apps/invoice.scss';
.table-design {
  width: 100%;
  border-collapse: collapse;
  border: none;
  outline: none;
  color: black;

  th,
  td {
    border: 1px solid black;
    padding: 10px;
  }
}

/*
@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
*/
@media print {
  //margin: 0;
  .break-footer {
    break-inside: auto;
  }
}
</style>
